import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/comparison"

const Comparison = ({ location }) => {
  const title = "Porównaj lokale"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
    </Layout>
  )
}

export default Comparison
